import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { ContextDispatch } from '../../context/Context'
import Photo from '../Photo'
import SlideMask from '../SlideMask'
import InViewSection from '../InViewSection'
import {
  PressLinksHeader,
  PressLinksHeaderContent,
  PressLinksHeaderName,
  PressLinksHeaderTitle,
  PressLinksImages,
  PressLinksImagesLabel,
  PressLinksImagesWrapper,
  PressLinkImage,
  PressLinkImageDate,
  PressLinkImageTitle,
  PressLinksMain,
  PressLinksWrapper,
  PressLinksNext,
} from './index.style'
import { animation } from '../../styles/vars/animation.style'
import AnimateSlideIn from '../_animation/AnimateSlideIn'
import { isDesktop } from 'react-device-detect'
import { breakpoints } from '../../styles/vars/breakpoints.style'
import PageTransitionLink from '../PageTransitionLink'

const PressLinks = ({
  items,
  title,
  name,
  typeLabel,
  next,
  urlPrefix,
  id,
  fullHeight,
}) => {
  const [fixHeader, setFixHeader] = useState(false)
  const storeDispatch = useContext(ContextDispatch)

  useEffect(() => {
    storeDispatch({ type: 'SET_VISIBLE_MENU' })

    if (isDesktop || window.innerWidth > breakpoints.horizontal) {
      if (window.scroll.on)
        window.scroll.on('call', (value, way) => {
          setFixHeader(way === 'enter')
        })
    }
  }, [storeDispatch])

  return (
    <PressLinksWrapper data-scroll-section id={id ? id : undefined}>
      {!fullHeight && (
        <PressLinksHeader
          id="PressLinksHeader"
          data-scroll
          data-scroll-call="PressLinksHeader"
          data-scroll-repeat="true"
          data-scroll-offset="0%"
        >
          <InViewSection>
            <PressLinksHeaderContent
              fixed={fixHeader}
              data-scroll
              data-scroll-sticky
              data-scroll-target="#PressLinksHeader"
            >
              {title && (
                <PressLinksHeaderTitle>
                  <AnimateSlideIn>{title}</AnimateSlideIn>
                </PressLinksHeaderTitle>
              )}

              {name && (
                <PressLinksHeaderName>
                  <AnimateSlideIn>{name}</AnimateSlideIn>
                </PressLinksHeaderName>
              )}
            </PressLinksHeaderContent>
          </InViewSection>
        </PressLinksHeader>
      )}

      {items && (
        <PressLinksMain fullHeight={fullHeight}>
          <PressLinksImages>
            <InViewSection>
              <PressLinksImagesLabel aria-hidden>
                <AnimateSlideIn
                  direction="left"
                  delay={animation.pageEntryDuration}
                >
                  {typeLabel}
                </AnimateSlideIn>
              </PressLinksImagesLabel>
            </InViewSection>

            <PressLinksImagesWrapper fullHeight={fullHeight}>
              {React.Children.toArray(
                items.map(link => (
                  <InViewSection>
                    <PageTransitionLink to={`${urlPrefix}${link.slug}`}>
                      <PressLinkImage>
                        <Photo
                          img={link.featureImage}
                          align="right"
                          alt={link.featureImage.description}
                          short={true}
                        />
                        {link.dateOfPublish && (
                          <PressLinkImageDate>
                            <AnimateSlideIn>
                              {link.dateOfPublish}
                            </AnimateSlideIn>
                          </PressLinkImageDate>
                        )}
                        <PressLinkImageTitle>
                          <AnimateSlideIn>
                            <SlideMask>{link.name}</SlideMask>
                          </AnimateSlideIn>
                        </PressLinkImageTitle>
                      </PressLinkImage>
                    </PageTransitionLink>
                  </InViewSection>
                ))
              )}
            </PressLinksImagesWrapper>
          </PressLinksImages>
          <PressLinksNext />
        </PressLinksMain>
      )}
    </PressLinksWrapper>
  )
}

PressLinks.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string,
  name: PropTypes.string,
  typeLabel: PropTypes.string,
  urlPrefix: PropTypes.string,
  id: PropTypes.string,
  fullHeight: PropTypes.bool,
}

export default PressLinks
