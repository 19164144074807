import React from 'react'
import PropTypes from 'prop-types'
import { PressTitleMain, PressTitleText } from './index.style'
import AnimateSplitText from '../_animation/AnimateSplitText'
import { animation } from '../../styles/vars/animation.style'

const PressTitle = ({ title }) => {
  const entryDelay = animation.PressExitDuration

  return (
    <PressTitleMain>
      <PressTitleText>
        <AnimateSplitText delay={entryDelay} heading>
          {title}
        </AnimateSplitText>
      </PressTitleText>
    </PressTitleMain>
  )
}

PressTitle.propTypes = {
  title: PropTypes.string.isRequired,
}

export default PressTitle
