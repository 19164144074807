import styled from 'styled-components'
import { horizontalScale } from '../../styles/utils/conversion.style'
import { font } from '../../styles/vars/font.style'
import { mq } from '../../styles/vars/media-queries.style'
import { horizontalPadding } from '../../styles/utils/functions.style'

export const PressTitleMain = styled.div`
  overflow: hidden;

  ${mq.belowHorizontal} {
    padding-top: 11.1rem;
    padding-bottom: 2rem;
    ${horizontalPadding()}
  }

  ${mq.horizontal} {
    padding-left: ${horizontalScale(125)};
  }
`

export const PressTitleText = styled.h1`
  position: relative;
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.extrabold};
  font-size: 10.3rem;
  line-height: 0.84;
  text-transform: uppercase;
  word-break: break-all;

  ${mq.mobileL} {
    max-width: none;
  }

  ${mq.tabletP} {
    font-size: 20rem;
  }

  ${mq.horizontal} {
    transform: translateX(${horizontalScale(10)}) translateY(${horizontalScale(116)});
    max-width: 2.55em;
    text-align: right;
    font-size: ${horizontalScale(605)};
    letter-spacing: -0.01em;
  }
`