import React from 'react'
import { graphql } from 'gatsby'
import { ScrollWrapper } from '../components/Scroll/index.style'
import SEO from '../components/SEO'
import { colors } from '../styles/vars/colors.style'
import ScrollSection from '../components/ScrollSection'
import PressTitle from '../components/PressTitle'
import PressLinks from '../components/PressLinks'

const IndexPage = ({ data }) => {
  const {
    title,
    seoDescription,
    pressItems,
  } = data.allContentfulPagePress.nodes[0]
  return (
    <>
      <SEO title={title} description={seoDescription.seoDescription} />

      <ScrollWrapper color={colors.light}>
        <ScrollSection width={1642}>
          <PressTitle title={title} />
        </ScrollSection>

        <PressLinks
          items={pressItems}
          title={'Press'}
          name={'Articles'}
          typeLabel={'Articles'}
          urlPrefix={'/press/'}
        />
      </ScrollWrapper>
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query PressPageQuery {
    allContentfulPagePress {
      nodes {
        title
        seoDescription {
          seoDescription
        }
        pressItems {
          featureImage {
            file {
              details {
                image {
                  height
                  width
                }
              }
            }
            description
            title
            gatsbyImageData
          }
          dateOfPublish
          name
          slug
        }
      }
    }
  }
`