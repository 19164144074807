import styled from 'styled-components'
import { colors } from '../../../styles/vars/colors.style'

export const AnimateImageMask = styled.div`
  position: relative;
  overflow: hidden;
`

export const AnimateImageFill = styled.div.attrs(props => ({
  style: {
    transitionDelay: `${props.delay}s`,
  },
}))`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.1rem;
  left: 0.1rem;
  transform: translateY(102%);
  background-color: ${colors.dark};
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);

  [data-inview='true'] & {
    transform: translateY(0%);
  }
`

export const AnimateImageMain = styled.div.attrs(props => ({
  style: {
    transitionDelay: `${0.6 * 0.6 + props.delay}s`,
  },
}))`
  transform-origin: 50% 0%;
  transform: translateY(110%) scale(${props => props.scale});
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1);

  [data-inview='true'] & {
    transform: translateY(0%) scale(1);
  }
`
