import styled, { css } from 'styled-components'
import { horizontalScale, setVh } from '../../styles/utils/conversion.style'
import { mq } from '../../styles/vars/media-queries.style'
import { horizontalPadding } from '../../styles/utils/functions.style'
import { colors } from '../../styles/vars/colors.style'
import { font } from '../../styles/vars/font.style'
import { PageTransitionLinkMain } from '../PageTransitionLink/index.style'
import { PhotoMain } from '../Photo/index.style'
import { easings } from '../../styles/vars/easings.style'

export const PressLinksWrapper = styled.div`
  ${mq.horizontal} {
    display: flex;
    flex-direction: column;
    ${setVh('height', 100)}
  }
`

export const PressLinksHeader = styled.div`
  display: none;

  ${mq.horizontal} {
    position: relative;
    display: flex;
    flex: 1;
    margin-left: ${horizontalScale(348)};
    min-height: ${horizontalScale(185)};
    padding-left: ${horizontalScale(180)};
    color: ${colors.light};
    background-color: ${colors.dark};

    :before {
      content: '';
      position: absolute;
      top: 0;
      right: -0.1rem;
      bottom: 0;
      display: block;
      width: 0.2rem;
      background-color: ${colors.dark};
    }
  }
`

export const PressLinksHeaderContent = styled.div`
  ${mq.horizontal} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
`

const PressLinksHeaderText = css`
  font-family: ${font.secondary};
  font-size: ${horizontalScale(38)};
  line-height: 1.2;
  text-transform: uppercase;
`

export const PressLinksHeaderTitle = styled.p`
  ${PressLinksHeaderText}
  font-variation-settings: ${font.weight.secondary.bold};
`

export const PressLinksHeaderName = styled.p`
  margin-top: ${horizontalScale(-10)};
  ${PressLinksHeaderText}
  font-variation-settings: ${font.weight.secondary.medium};
`

export const PressLinksHeaderCTA = styled.p`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-family: ${font.secondary};
  font-size: ${horizontalScale(58)};
  font-variation-settings: ${font.weight.secondary.extrabold};
  line-height: 1.2;
  text-transform: uppercase;

  ${mq.horizontal} {
    top: ${horizontalScale(84)};

    ${PageTransitionLinkMain} {
      :before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        transform-origin: 0% 50%;
        transform: scaleX(0) translateZ(0);
        display: block;
        height: 0.2rem;
        background-color: ${colors.light};
        transition: transform 0.3s ${easings.out.cubic};
      }

      :hover:before,
      :focus:before {
        transform: scaleX(1) translateZ(0);
      }
    }
  }
`

export const PressLinksMain = styled.div`
  ${mq.horizontal} {
    height: 100%;
    padding-top: ${horizontalScale(66)};
    background-color: ${colors.light};
  }
`

export const PressLinksImages = styled.div`
  display: inline-block;
  position: relative;
  font-size: 0px;
  vertical-align: top;

  ${mq.belowHorizontal} {
    overflow: scroll;
  }
`

export const PressLinksImagesLabel = styled.p`
  display: none;

  ${mq.horizontal} {
    position: absolute;
    top: 0;
    left: ${horizontalScale(36)};
    transform-origin: 0% 0%;
    transform: rotate(90deg);
    display: block;
    font-size: ${horizontalScale(12)};
    line-height: 1;
    text-transform: uppercase;
  }
`

export const PressLinksImagesWrapper = styled.div`
  ${horizontalPadding()}

  ${mq.horizontal} {
    display: inline-flex;
    align-items: flex-start;
    padding-left: ${horizontalScale(145)};
    padding-right: ${horizontalScale(146)};
    padding-bottom: ${horizontalScale(64)};
  }

  > * + * {
    ${mq.horizontal} {
      margin-left: ${horizontalScale(71)};
    }
  }

  ${PhotoMain} {
    ${mq.belowHorizontal} {
      padding-right: 0;
      width: 100%;
    }
  }
`

export const PressLinkImage = styled.div`
  ${mq.belowHorizontal} {
    margin-bottom: 6rem;
  }
`

const PressLinkTextStyles = css`
  width: 90%;

  ${mq.horizontal} {
    max-width: ${horizontalScale(347)};
    width: calc(100% - ${horizontalScale(34)});
  }
`

export const PressLinkImageDate = styled.span`
  ${PressLinkTextStyles}
  display: block;
  font-size: 1.2rem;
  margin-top: -1.4rem;
  padding-bottom: 1rem;
  text-transform: uppercase;
`

export const PressLinkImageTitle = styled.h3`
  ${PressLinkTextStyles}
  position: relative;
  font-family: ${font.secondary};
  font-variation-settings: ${font.weight.secondary.bold};
  font-size: 3.8rem;
  line-height: 1;
  text-transform: uppercase;

  ${mq.horizontal} {
    font-size: ${horizontalScale(38)};
  }
`

export const PressLinksNext = styled.div`
  ${mq.horizontal} {
    background-color: ${colors.dark};
    display: inline-block;
    height: calc(100% + ${horizontalScale(67)});
    margin-top: ${horizontalScale(-67)};
    width: ${horizontalScale(254)};
  }
`