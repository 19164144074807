import React from 'react'
import PropTypes from 'prop-types'
import {
  AnimateImageMain,
  AnimateImageFill,
  AnimateImageMask,
} from './index.style'

const AnimateImage = ({ children, delay, scale }) => (
  <AnimateImageMask>
    <AnimateImageFill delay={delay} />
    <AnimateImageMain delay={delay} scale={scale ? 1.8 : 1}>{children}</AnimateImageMain>
  </AnimateImageMask>
)

AnimateImage.propTypes = {
  children: PropTypes.node,
  delay: PropTypes.number,
  scale: PropTypes.bool,
}

AnimateImage.defaultProps = {
  delay: 0,
  scale: true
}

export default AnimateImage
