import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import AnimateSlideIn from '../_animation/AnimateSlideIn'
import AnimateImage from '../_animation/AnimateImage'
import {
  PhotoDescription,
  PhotoMain,
  PhotoTitle,
  PhotoWrapper,
} from './index.style'

const Photo = ({
  img,
  title,
  description,
  delay,
  align,
  width,
  alt,
  short,
}) => {
  if (!img) return null

  const image = img.file ? img.file.details.image : img.gatsbyImageData
  const imageWidth = width ? width : image.width
  const imageHeight = width
    ? (image.height / image.width) * width
    : image.height

  return (
    <PhotoMain
      align={align}
      width={imageWidth}
      height={imageHeight}
      short={short}
    >
      <PhotoWrapper>
        <AnimateImage delay={delay}>
          <GatsbyImage
            loading="eager"
            placeholder="dominantColor"
            image={img.gatsbyImageData}
            alt={alt ? alt : `${title}: ${description}`}
          />
        </AnimateImage>
      </PhotoWrapper>

      {title && (
        <PhotoTitle align={align} aria-hidden>
          <AnimateSlideIn delay={delay} direction="right">
            {title}
          </AnimateSlideIn>
        </PhotoTitle>
      )}

      {description && (
        <PhotoDescription align={align} aria-hidden>
          <AnimateSlideIn delay={delay} direction="left">
            {description}
          </AnimateSlideIn>
        </PhotoDescription>
      )}
    </PhotoMain>
  )
}

Photo.propTypes = {
  img: PropTypes.object.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  delay: PropTypes.number,
  width: PropTypes.number,
  align: PropTypes.oneOf(['left', 'right', 'full']),
  alt: PropTypes.string,
  short: PropTypes.bool,
}

Photo.defaultProps = {
  delay: 0,
  align: 'right',
  short: false,
}

export default Photo
