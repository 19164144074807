import styled from 'styled-components'
import { horizontalScale } from '../../styles/utils/conversion.style'
import { font } from '../../styles/vars/font.style'
import { mq } from '../../styles/vars/media-queries.style'

export const photoPadding = 24
export const photoPaddingHorizontal = 34

export const PhotoMain = styled.div`
  position: relative;
  width: ${({ width, align }) =>
    align === "full"
      ? `100%`
      : `${width / 40 + photoPadding / 10}rem`
  };
  padding-bottom: ${photoPadding / 10}rem;
  ${({ align }) => align === 'left' && `padding-left: ${photoPadding / 10}rem`};
  ${({ align }) => align === 'right' && `padding-right: ${photoPadding / 10}rem`};

  ${mq.horizontal} {
    width: ${({ width, height, align, short }) => {
      if (align === "full") return `100%`
      if (height / 2 > (short ? 600 : 730)) {
        const aspectRatio = width / height
        return horizontalScale((short ? 600 : 730) * aspectRatio + photoPaddingHorizontal)
      } else {
        return horizontalScale(width / 2 + photoPaddingHorizontal)
      }
    }};
    padding-bottom: ${horizontalScale(photoPaddingHorizontal)};
    ${({ align }) => align === 'left' && `padding-left: ${horizontalScale(photoPaddingHorizontal)}`};
    ${({ align }) => align === 'right' && `padding-right: ${horizontalScale(photoPaddingHorizontal)}`};
  }
`

export const PhotoWrapper = styled.div`
  font-size: 0;
  position: relative;
`

export const PhotoMask = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export const PhotoTitle = styled.p`
  position: absolute;
  bottom: 0;
  ${({ align }) => (align === 'left' ? 'left: 0' : 'right: 0')};
  transform-origin: ${({ align }) =>
    align === 'left' ? '100% 100%' : '100% 0%'};
  transform: ${({ align }) =>
    align === 'left'
      ? 'translateX(-100%) rotate(90deg)'
      : 'translateY(80%) rotate(90deg)'};
  font-weight: ${font.weight.bold};
  font-size: 1.2rem;
  line-height: 1.24;
  text-transform: uppercase;

  ${mq.horizontal} {
    font-size: ${horizontalScale(12)};
  }
`

export const PhotoDescription = styled.p`
  position: absolute;
  bottom: 0;
  ${({ align }) =>
    align === 'full'
      ? `right: 0`
    : align === 'left'
        ? `left: ${photoPadding / 10}rem`
        : `right: ${photoPadding / 10}rem`};
  font-size: 1.2rem;
  line-height: 1.24;

  ${mq.horizontal} {
    ${({ align }) =>
      align === 'full'
        ? `right: 0`
      : align === 'left'
          ? `left: ${horizontalScale(photoPaddingHorizontal)}`
          : `right: ${horizontalScale(photoPaddingHorizontal)}`};
    font-size: ${horizontalScale(12)};
  }
`
